.wrapper {
    height: 82vh;
    min-height: 400px;
    overflow-y: auto;
    border: 1px solid #c1c1c1;
}

.theoryAndTask_container {
    padding: 1rem 1.5rem;
    font-size: 16px;
    color: #2a2a2a;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.theoryAndTask_container img {
    display: block;
    width: 100%;
    margin: 1rem 0;
    border: 1px solid #c1c1c1;
}

.theoryAndTask_container summary {
    user-select: none;
    cursor: pointer;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.3px;
}

.theoryAndTask_container summary::-webkit-details-marker {
    font-size: 14px;
    color: #2a2a2a;
    margin-bottom: 3px;
}


/* details summary::-webkit-details-marker {
    display: none
}

details>summary {
    list-style: none;
}

details summary:before {
    content: '\2740';
    font-family: "Font Awesome 5 free";
    margin-right: 7px;
}

details[open] summary:before {
    content: '\260E';
} */



.theoryAndTask_container code {
    width: 100%;
    height: auto;
    margin: 1rem 0;
}

.theoryAndTask_container p {
    font-size: 16px;
    color: #2a2a2a;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.theoryAndTask_container>h2 {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 28px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    background-color: #f7f7f7;
    padding-left: 35px;
    position: relative;
}

.theoryAndTask_container>h2::before {
    content: '\270E';
    position: absolute;
    top: 0;
    left: 10px;
}

.theoryAndTask_container h3 {
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 28px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    /* background-color: #f7f7f7; */
}

.theoryAndTask_container h4 {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 28px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    /* background-color: #f7f7f7; */
}

.theoryAndTask_container ul {
    padding-left: 20px;
}

.theoryAndTask_container ol {
    padding-left: 20px;
}

.theoryAndTask_container li {
    font-size: 16px;
    line-height: 26px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.theoryAndTask_container strong {
    color: #292929;
}

.theoryAndTask_container li strong {
    color: #292929;
}

.theoryAndTask_container code {
    background-color: #f7f7f7;
}

.theoryAndTask_container pre>code {
    /* color: #292929; */
    color: #2a2a2a;
}


/* Стилизация кнопок переключения */

/* .buttonSelect_container {
    position: absolute;
    top: -25px;
    left: 0;
    display: flex;
}

.buttonSelect_container button {
    font-size: 13px;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 8px 11px;
    letter-spacing: 1px;
}

.activeBtn {
    color: #ffffff;
    background-color: #ff6b08;
    border: 1px solid #ff6b08;
} */

.layout {
    width: 100%;
    max-height: 75vh;
    cursor: pointer;
}

.layoutTitle {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 28px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding-left: 1.5rem;
}

.layoutText {
    font-size: 16px;
    color: #2a2a2a;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    padding-left: 1.5rem;
}