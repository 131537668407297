.wrapper {
    width: 360px;
    background-color: #fff;
    position: relative;
    overflow-y: auto;
    max-height: 90vh;
}

.modalTitle {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    background-color: #f7f7f7;
    padding: 20px 0;
}

.contentWrapper {
    padding: 15px 58px 38px 58px;
    display: flex;
    flex-direction: column;
}

.text {
    font-size: 14px;
    line-height: 26px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: center;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.button {
    width: 200px;
    align-self: center;
    font-size: 14px;
    line-height: 26px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 15px 30px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 0.3px;
    margin-top: 1rem;
    text-decoration: none;
}

.button:hover {
    background-color: #e96105;
}