.code_container {
    width: 100%;
    height: 40vh;
    min-height: 180px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

@media (max-height: 600px) {
    .code_container {
        width: 100%;
        height: calc(38vh + 1.5rem);
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        overflow: hidden;
    }
}